<template>
  <AppContainer>
    <AppHeader />
    <div
      class="min-h-full flex-1 bg-brand-navy-600 md:bg-brand-navy-700 mb-auto"
    >
      <slot />
    </div>
    <AppFooter />
  </AppContainer>
</template>
